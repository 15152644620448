import { createContext, useState, useContext, ReactNode } from 'react'
import { TopicContextType, TopicType } from './Topic.type'

type TopicProviderProps = {
  children: ReactNode
}

const TopicContext = createContext<TopicContextType>({
  topics: [],
  setTopic: () => null,
})

export const TopicContextProvider = ({ children }: TopicProviderProps) => {
  const [topics, setTopic] = useState<TopicType[]>([])

  return <TopicContext.Provider value={{ topics, setTopic }}>{children}</TopicContext.Provider>
}

export const useTopicContext = () => {
  const { topics, setTopic } = useContext(TopicContext)

  return { topics, setTopic }
}
