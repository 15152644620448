import React from 'react';
const InputField: React.FC<InputValues> = (props) => {
  const{ type, placeholder, value, name, handleChangeInput} = props

  return (
    <>
    <input
       className='inputLabel'
       type={type}
       placeholder={placeholder}
       value={value}
       name={name}
       onChange={handleChangeInput}
     />
     </>  
  )  
}

interface InputValues {
  type: string
  placeholder: string
  value: string
  name: string
  handleChangeInput: (event: any) =>void
}
export default InputField;
