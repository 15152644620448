import React, { useContext, useState } from 'react';


type subCategoryDatatype = {
    title: string,
    uid: string
}
type SubCategoryContextType = {
    subCategory: subCategoryDatatype[],
    setsubCategory: CallableFunction,
}


const inititalvalue = {
    subCategory: [{
        title: '',
        uid: '',
    }],
    setsubCategory: () => null

}
const SubCategoryContext = React.createContext<SubCategoryContextType>(inititalvalue);


export default SubCategoryContext;


export const SubCategoryContextProvider = ({ children }: SubCategoryProviderType) => {
    const [subCategory, setsubCategory] = useState([]);
    return <SubCategoryContext.Provider value={{ subCategory, setsubCategory }}>{children}</SubCategoryContext.Provider>
}

export const useSubcategoryContext = () => {
    return useContext(SubCategoryContext);
};


type SubCategoryProviderType = {
    children: React.ReactNode
}