import { createContext, useState, useContext, ReactNode, useEffect } from 'react'
import { policyType, policyContextType } from './Policy.type'
import { getPolicyByUser } from '../api/policy'
import { useNavigate } from 'react-router-dom'

type PolicyProvideProps = {
    children: ReactNode
}

const PolicyContext = createContext<policyContextType>({
    policy: [],
    setPolicy: () => null,
})

export const PolicyContextProvider = ({ children }: PolicyProvideProps) => {
    const [policy, setPolicy] = useState<any>()

    return <PolicyContext.Provider value={{ policy, setPolicy }}>{children}</PolicyContext.Provider>
}

export const usePolicyContext = () => {
    const { policy, setPolicy } = useContext(PolicyContext)

    return { policy, setPolicy }
}
