import { useEffect, useState } from 'react';

export const useMobileScreen = () => {
  const [size, setsize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setsize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size < 1024;
};
