import React, { ReactElement } from 'react'
import { useTopicContext } from '../../../../context/Topic'
import { TopicType } from '../../../../context/Topic.type'
import { createTopicItem } from '../../../../helper/utils'
import { ContactInfo } from '../../Desktop/constant'
import { DropDown } from './DropDown'
import { TabContentWrapper } from './TabContentWrapper'
import TabNavItem from './TabNavItem'

const getContent = (id: string, topics: TopicType[]): ContentReturn => {
  switch (id) {
    case 'home-tab':
      return {
        className: '',
        element: <></>,
      }

    case 'search-tab':
      return {
        className: 'active show',
        element: (
          <div className='mob-feature-nav'>
            <div className='search-box'>
              <i className='bi bi-search'></i>
              <input
                type='text'
                className='forn-control'
                placeholder='Search for keywords or themes...'
              />
              <button className='btn btn-blue' type='button'>
                Search
              </button>
            </div>
          </div>
        ),
      }

    case 'profile-tab':
      return { className: '', element: <></> }
    case 'features-tab':
      return {
        className: 'active show',
        element: (
          <div className='mob-feature-nav'>
            <TabNavItem data={createTopicItem(topics)} />
          </div>
        ),
      }

    case 'more-tab':
      return {
        className: 'active show',
        element: (
          <div className='mob-feature-nav'>
            <TabNavItem data={ContactInfo} />
          </div>
        ),
      }

    case 'dot-tab':
      return {
        className: 'active show',
        element: <DropDown />,
      }

    default:
      return {
        className: '',
        element: <></>,
      }
  }
}

export const TabContent: React.FC<TabContent> = ({ id, target, labelledBy }) => {
  const { topics } = useTopicContext()
  const { className, element } = getContent(id, topics)
  return (
    <TabContentWrapper className={className} target={target} labelledBy={labelledBy}>
      {element}
    </TabContentWrapper>
  )
}

export default TabContent

type TabContent = {
  id: string
  target: string
  labelledBy: string
}

type ContentReturn = {
  className: string
  element: ReactElement
}
