import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'

export const TabListItem: React.FC<TabListItem> = (item) => {
  const location = useLocation()

  const getClassName = (item: any) => {
    if (item?.isSelected || matchPath({ path: item.href }, location.pathname)) {
      return 'active'
    }

    if (item?.disabled) {
      return 'disabled'
    }
    return ''
  }

  return (
    <li className={`nav-item' ${item.className} `} role='presentation'>
      <a
        className={`nav-link ${getClassName(item)}`}
        id={item.id}
        data-bs-toggle='tab'
        data-bs-target={item.target}
        type='button'
        role='tab'
        aria-disabled={item.disabled}
        aria-controls={item.controls}
        aria-selected={item.selected}
        onClick={() =>
          item.onClick({ id: item.id, target: item.controls, labelledBy: item.id, href: item.href })
        }
      >
        {item.children}
      </a>
    </li>
  )
}

type TabListItem = {
  className?: string
  id: string
  title: string
  target: string
  controls: string
  selected: boolean
  children: React.ReactElement | null
  onClick: CallableFunction
  disabled: boolean
  href: string
}
