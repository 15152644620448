import axios from 'axios'
import { END_POINTS } from './domains'

export const getPolicy = async () => {
    try {
        const res = await axios.get(END_POINTS.POLICY)
        return {
            result: res.data,
            error: false
        }
    } catch (error) {
        return { error: true }
    }
}

export const updatePolicy = async (data: any) => {
    const tokenString = localStorage.getItem('userInfo');
    const token = tokenString ? JSON.parse(tokenString) : null;
    try {
        const res = await axios.post(END_POINTS.UPDATE_POLICY, data, {
            headers: {
                authorization: token.token,
                sessionId: token.sessionId
            },
        })
        return {
            result: res,
            error: false
        }
    } catch (error) {
        return { error: true }
    }
}

export const getPolicyByUser = async (token: any) => {
    try {
        const res = await axios.post(END_POINTS.ACCEPTED__POLICE, null, {
            headers: {
                authorization: token.token,
                sessionId: token.sessionId
            },
        })
        return {
            result: res,
            error: false
        }
    } catch (error) {
        return { error: true }
    }
}