import React from 'react'
import { useTranslation } from 'react-i18next'

export const FootageCards: React.FC<FootageCards> = ({
  img,
  onClick,
  title,
  discription,
  tags,
}) => {
  const { t } = useTranslation()

  return (
    <div className='video-card' onClick={() => onClick()}>
      <div className='video-tags'>
        <ul>
          {tags?.map((item, key) => (
            <li key={key}>{item}</li>
          ))}
        </ul>
      </div>
{/* <img src={img} alt='img' className='video-sm' />*/}
<img src={`https://creatorportal-prod-msg.s3.amazonaws.com/images/thumbs/${title.trim()}.png`} alt='img' className='video-sm' />

      <div className='video-title'>
        <h3>{t(title)}</h3>
        <p>{t(discription)}</p>
      </div>
    </div>
  )
}

type FootageCards = {
  img: string
  onClick: CallableFunction
  title: string
  discription: string
  tags: string[]
}
