import React, { useEffect } from 'react'
import { AuthActionNames, useAuthContext } from '../context/Auth'
import { useNavigate } from 'react-router-dom'
import PrivacyPolicy from '../pages/Policies/PrivacyPolicy'
import { updatePolicyAcceptedStatus } from '../api/Auth.services'
import LogIn from '../pages/login'

export const makeProtectedRoutes = (Component: React.ComponentType) => {
  return function WrappedComponent(props: any) {
    const { state, dispatch } = useAuthContext()
    const policyAccepted = state?.user?.policyAccepted
    const navigate = useNavigate()
    const { isAuth } = state

    useEffect(() => {
      if (!isAuth) navigate('/login')
    }, [isAuth])

    return isAuth ? <Component {...props} /> : null
  }
}
