import React from 'react'
import { back, next } from '../../helper/iconPath'
import { usePagination, DOTS } from '../../hooks/usePagination'
import './Pagination.scss'

const Pagination: React.FC<Pagination> = (props) => {
  const { totalCount, onPageChange, currentPage } = props

  const paginationRange = usePagination({
    totalCount: totalCount,
    pageSize: 10,
    siblingCount: 1,
    currentPage: currentPage,
  })

  const lastPage = paginationRange[paginationRange.length - 1]

  const onNext = () => {
    if (currentPage !== lastPage) onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1)
    }
  }

  const getClassName = (pageNumber: number | string) => {
    if (currentPage === pageNumber) {
      return 'active'
    }
    return ''
  }

  return (
    <ul className='pagination'>
      <li
        className={`pagination-item ${currentPage === 0 ? 'disabled' : ''} }`}
        onClick={onPrevious}
      >
        <span aria-hidden='true'>
          <img src={back} alt='back' />
        </span>
      </li>

      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li className='pagination-item dots' key={index}>
              &#8230;
            </li>
          )
        }

        return (
          <li
            key={index}
            className={`pagination-item ${getClassName(pageNumber)}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      })}
      <li
        className={`pagination-item ${currentPage === lastPage ? 'disabled' : ''}`}
        onClick={onNext}
      >
        <span aria-hidden='true'>
          <img src={next} alt='next' />
        </span>
      </li>
    </ul>
  )
}

type Pagination = {
  onPageChange: CallableFunction
  currentPage: number
  totalCount: number
}

export default Pagination
