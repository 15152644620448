import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetArticle } from '../../api/Topic.services'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import Loader from '../../components/Loader/Loader'
import { useTopicContext } from '../../context/Topic'
import { TopicType } from '../../context/Topic.type'
import { logoImage } from '../../helper/iconPath'
import Features from '../Home/Features'
import CategoryLinks from './CategoryLinks'
import './category.scss'
import { useAuthContext } from '../../context/Auth'
import { useLoaderContext } from '../../context/LoaderContect'
import { usePolicyService } from '../../hooks/usePolicyService'
// import { carouselScript } from '../../helper/constants'

export const Category: React.FC<CategoryProps> = (props) => {
  console.log('props ', props)
  const { isLoading } = useLoaderContext()
  const authData = useAuthContext()
  const policyAccepted = authData?.state?.user?.policyAccepted
  const { id } = useParams()
  const { topics } = useTopicContext()
  const [currentCategory, setCategory] = useState<any>()
  const getData = usePolicyService()

  const navigate = useNavigate()
  useEffect(() => {
    const item = topics.find((item) => item.uid === id)
    const fetchData = async () => {
      if (id || props?.articleName) {
        const result = await GetArticle(item?.article ? item?.article : props?.articleName || '')
        getData(result.data.policyAccepted)
        if (!result.error) {
          if (item) {
            setCategory({ ...item, ...result?.data?.article, name: item?.title || '' })
            console.log('setCategory ', {
              ...item,
              ...result?.data?.article,
              name: item?.title || '',
            })
          } else {
            setCategory({ ...result?.data?.article, name: result?.data?.article?.title })
            console.log('setCategory ', {
              ...result?.data?.article,
              name: result?.data?.article?.title,
            })
          }
          // this is jquery script which will only work for the carousel coming from the database
          $('#carouselSlider').ready(function () {
            $('.owl-carousel').owlCarousel({
              loop: true,
              margin: 10,
              responsiveClass: true,
              dots: true,
              responsive: {
                0: {
                  items: 1,
                  nav: true,
                },
                600: {
                  items: 1,
                  nav: false,
                },
                1024: {
                  items: props?.articleName === 'landing_page' ? 3 : 1,
                  nav: true,
                  loop: false,
                  margin: 20,
                },
              },
            })
          })
        }
        if (result.error && result.code === 403) {
          navigate('/login')
        }
      }
    }
    fetchData()
  }, [id, topics])

  const handleclick = (uid: string) => {
    navigate(`subcategory/${uid}`)
  }

  function createMarkup(htmlString: string) {
    return { __html: htmlString }
  }

  return (
    <div className='category__wrapper test'>
      {!currentCategory || Object.keys(currentCategory).length === 0 || isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='msg-sphere-logo'>
            <img src={logoImage} alt='logo' />
          </div>

          <section
            className='category-page'
            style={{ backgroundImage: `url(${currentCategory?.heroBanner})` }}
          >
            {currentCategory?.icon && (
              <section>
                {/* <img src={`data:image/png;base64,${currentCategory?.icon}`} alt='audio tech' /> */}
                {/* style={{backgroundImage:`url(data:image/gif;base64,${currentCategory?.icon})`}} */}
              </section>
            )}
            {props?.articleName && props?.articleName !== 'landing_page' ? (
              <Breadcrumb route={[{ link: 'about', text: currentCategory?.name }]} />
            ) : (
              ''
            )}

            {currentCategory?.content && (
              <div dangerouslySetInnerHTML={createMarkup(currentCategory?.content)} />
            )}

            {props?.articleName && props?.articleName === 'landing_page' ? (
              <div className='mb-5'>
                <Features />
              </div>
            ) : (
              ''
            )}

            {!props?.articleName ? (
              <CategoryLinks info={currentCategory} handleclick={handleclick} />
            ) : (
              ''
            )}
          </section>
        </>
      )}
    </div>
  )
}

type CategoryProps = {
  articleName?: string
}

type ArticleType = {
  content: string
  description: string
  subtitle: string
  title: string
  uid: string
}
export type CategoryInfo = TopicType & ArticleType
