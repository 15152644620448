import { useLocation, useNavigate, useParams } from 'react-router-dom'

import './breadcrumb.scss'
import { useEffect } from 'react';

function Breadcrumb(props: breadcrumbType) {
  const { route } = props

  const params = useParams()
  const location = useLocation()
  let path = location.pathname.split('/')
  const navigate = useNavigate()

  const dynamicPart = Object.values(params)
  path = path.filter((item) => !dynamicPart.includes(item))

  useEffect(() => {
    /**
     * Whenever use click on any nav link always scroll to top
     */
    window.scrollTo(0, 0);
  }, [params]);

  return (
    <ol className='breadcrumb'>
      <li className='breadcrumb-item'>
        <a aria-disabled={false} onClick={() => navigate('/')}>
          Home
        </a>
      </li>
      {route.map((nitem: any, key: number) => {
        return (
          <li
            className={
              path[path.length - 1] === nitem.link ? 'active breadcrumb-item' : 'breadcrumb-item'
            }
            key={key}
          >
            <a
              aria-disabled={path[path.length - 1] === nitem.link}
              onClick={() => {
                if (path[path.length - 1] !== nitem.link) {
                  navigate(`/${nitem.link}`)
                }
              }}
            >
              {nitem.text}
            </a>
          </li>
        )
      })}
    </ol>
  )
}

export default Breadcrumb

type breadcrumbType = {
  route: { text: string; link: string }[]
}
