import React, { useEffect, useState } from 'react'
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// this will return the nav item component will render nested child : TODO : make it recursive and add listeners
export const NavItem: React.FC<NavItem> = (item) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const params = searchParams.get('tag')

  const [isOpen, setOpen] = useState(false)

  const getClassName = (item: any) => {
    if (item?.isSelected || matchPath({ path: item.href }, location.pathname)) {
      return 'active'
    }

    if (item?.disabled) {
      return 'disable'
    }
    return ''
  }

  useEffect(() => {
    const present = item.children.find((child) =>
      matchPath({ path: child.href }, location.pathname),
    )
    const itSelf = matchPath({ path: item.href }, location.pathname)

    if (present || item.isSelected || itSelf) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [location])

  const handleTopicClick = (e: any) => {
    e.stopPropagation()
    setOpen(true)
    if (item?.disabled) {
      return
    }
    item.onClick(item.title)
    navigate(item.href)
  }

  const handleTopic = () => {
    setOpen((prevState) => !prevState)
  }

  const getSelectedTag = (item: any) => {
    if (params === item.title) {
      return 'active'
    } else {
      return ''
    }
  }
  return (
    <li className={`nav-item ${item.className} `} data-toggle="tooltip" data-placement="top" title={item.disabled ? 'Coming Soon' : ''}>
      <a aria-disabled={item.disabled} className={`nav-link ${getClassName(item)}`}>
        {item.icon && <img src={item.icon} alt='icon' />}{' '}
        <span onClick={handleTopicClick} className='cursorHighlate'>
          {item.title}
        </span>
        {item.children.length > 0 && item.type != 'footage' && (
          <i
            className={`bi bi-chevron-${isOpen ? 'up' : 'down'} cursorHighlate`}
            onClick={handleTopic}
          ></i>
        )}
      </a>

      {item.children.length > 0 && item.type != 'footage' && (
        <ul className={`submenu ${isOpen ? 'collapse show' : 'collapse'} `}>
          {item.children.map((item, index) => {
            return (
              <li key={index}>
                <a
                  className={`nav-link ${getClassName(item)} ${getSelectedTag(
                    item,
                  )} cursorHighlate`}
                  onClick={() => navigate(item.href)}
                >
                  {item.title}
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}

export type NavChild = {
  href: string
  title: string
}

type NavItem = {
  icon?: string | undefined
  className?: string
  href: string
  title: string
  onClick: CallableFunction
  isSelected?: boolean
  disabled?: boolean
  children: Array<NavChild>
  type?: string | null
}
