import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { TabList } from './constant'
import './SideNavbar.scss'
import { TabContent } from './Tabs/TabContent'

import { TabListItem } from './Tabs/TabListItem'

const initalState = {
  id: '',
  target: '',
  labelledBy: '',
  href: '',
}

export const MobileSideNavbar: React.FC = () => {
  const navigation = useNavigate()
  const location = useLocation()

  const [state, setState] = useState<TabState>(initalState)
  const onClick = (tabInfo: TabState) => {
    setState(tabInfo.id === state.id ? { ...initalState } : tabInfo)
    if (tabInfo.id === 'home-tab') {
      navigation(tabInfo.href)
    }
  }

  useEffect(() => {
    setState(initalState)
  }, [location])

  return (
    <section className='sidebar-menu  mobile-nav'>
      <p className='brand-name'>CREATOR PORTAL</p>
      <nav className='sidebar card py-2 mb-4'>
        <ul className='mob-nav nav flex-column nav-tabs' id='myTab' role='tablist'>
          {TabList.map((item, index) => (
            <TabListItem key={index} {...item} selected={item.id === state.id} onClick={onClick}>
              {item.id !== 'dot-tab' ? (
                <>
                  <img src={item.icon} alt='dots' />
                  {item.title}
                </>
              ) : (
                <div className='three-dots'>
                  <img src={item.icon} alt='dots' />
                </div>
              )}
            </TabListItem>
          ))}
        </ul>
        <div className='tab-content' id='myTabContent'>
          <TabContent {...state} />
        </div>
      </nav>
    </section>
  )
}

type TabState = {
  id: string
  target: string
  labelledBy: string
  href: string
}
