import { createContext, useReducer, useContext, ReactNode } from 'react'
import { AuthContextType, AuthType, AuthActionType, userInfoType } from './Auth.type'
type AuthProviderProps = {
  children: ReactNode
}

const userInfo: userInfoType | null = JSON.parse(String(localStorage.getItem('userInfo')))

const initialState = {
  isAuth: userInfo ? true : false,
  user: {
    firstName: userInfo?.firstName || '',
    lastName: userInfo?.lastName || '',
    username: userInfo?.username || '',
    policyAccepted: userInfo?.policyAccepted || false,
    email: userInfo?.email || '',
  },
}

const AuthContext = createContext<AuthContextType>({
  state: initialState,
  dispatch: () => null,
})

export const AuthActionNames = {
  login: 'LOG_IN',
  logout: 'LOG_OUT',
  update: 'UPDATE',
}

const authReducer = (state: AuthType, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionNames.login:
      return {
        isAuth: true,
        user: action.payload,
      }

    case AuthActionNames.logout:
      return {
        isAuth: false,
        user: initialState.user,
      }

    case AuthActionNames.update:
      return {
        isAuth: true,
        user: { ...initialState.user, policyAccepted: true },
      }

    default:
      return state
  }
}

export const AuthContextProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, initialState)
  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
  const { state, dispatch } = useContext(AuthContext)
  return { state, dispatch }
}
