import React from 'react'

const LoginCarousel: React.FC<CarouselInputs> = (props) => {
  const { handleLabelColor, handleChange } = props

  return (
    <>
      <div
        className={handleLabelColor(0)}
        onClick={() => {
          handleChange(0)
        }}
      ></div>
      <div
        className={handleLabelColor(1)}
        onClick={() => {
          handleChange(1)
        }}
      ></div>
      <div
        className={handleLabelColor(2)}
        onClick={() => {
          handleChange(2)
        }}
      ></div>
    </>
  )
}

interface CarouselInputs {
  handleLabelColor: (value: number) => string
  handleChange: (value: number) => void
}
export default LoginCarousel
