import React from 'react'
import { useNavigate } from 'react-router-dom'

export const FooterItem: React.FC<FooterItem> = ({ className, links, title }) => {
  const navigate = useNavigate()
  return (
    <div className={className} >
      <h5>{title}</h5>
      <ul>
        {links.map((item, index) => (
          <li key={index} data-toggle="tooltip" data-placement="top" title={item.disabled ? 'Coming Soon' : ''}>
            {
              item.disabled ?
                <div
                  onClick={() => { item?.title && item.title !== 'Contact' ? navigate(item.href) : () => { window.open(item.href, '_blank') } }}
                  aria-disabled={item.disabled}
                  className={`${item.disabled ? 'disabled' : ''}`}
                >
                  {item.title}
                </div> :
                <a
                  href={item?.title && item.title === 'Contact' ? item.href : ''}
                  onClick={() => { item?.title && item.title !== 'Contact' ? navigate(item.href) : () => { window.open(item.href, '_blank') } }}
                  aria-disabled={item.disabled}
                  className={`${item.disabled ? 'disabled' : ''}`}
                >
                  {item.title}
                </a>
            }
          </li>
        ))}
      </ul>
    </div>
  )
}

type FooterLink = {
  href: string
  title: string
  disabled: boolean
}

export type FooterItem = {
  className: string
  title: string
  links: FooterLink[]
}
