export const TabList = [
  {
    id: 'home-tab',
    title: 'Home',
    icon: '/images/icon-home.svg',
    target: '#home-tab-pane',
    controls: 'home-tab-pane',
    href: '/',
    disabled: false,
  },
  {
    id: 'search-tab',
    title: 'Search',
    icon: '/images/icon-search.svg',
    target: '#search-tab-pane',
    controls: 'search-tab-pane',
    href: '#',
    disabled: true,
  },
  {
    id: 'user-tab',
    title: 'Profile',
    icon: '/images/icon-user.svg',
    target: '#user-tab-pane',
    controls: 'user-tab-pane',
    href: '#',
    disabled: true,
  },
  {
    id: 'features-tab',
    title: 'Features',
    icon: '/images/icon-features.svg',
    target: '#features-tab-pane',
    controls: 'features-tab-pane',
    href: '#',
    disabled: false,
  },
  {
    id: 'more-tab',
    title: 'More',
    icon: '/images/icon-more.svg',
    target: '#more-tab-pane',
    controls: 'more-tab-pane',
    href: '#',
    disabled: false,
  },
  {
    className: 'three-dot',
    id: 'dot-tab',
    title: '',
    icon: '/images/icon-three-dots.svg',
    target: '#dot-tab-pane',
    controls: 'dot-tab-pane',
    href: '#',
    disabled: false,
  },
]

export const FeaturesList = [
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: ' Venue Overview',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },

  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: ' Project Ideation',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: ' Camera',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: ' Shoot Techniques',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: 'Test Footage',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: 'Screens',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: 'Audio',
    children: [
      {
        href: '#',
        title: 'Physical Deployment',
      },
      {
        href: '#',
        title: 'Core Technology',
      },
      {
        href: '#',
        title: 'Capabilities & Configurations',
      },
      {
        href: '#',
        title: 'Capture',
      },
      {
        href: '#',
        title: 'Mixing & Post Production',
      },
      {
        href: '#',
        title: 'Appendices / Glossaary',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: 'Atmospheric Effects',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: ' Creator Tools ',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: ' Interactive ',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
  {
    className: 'has-submenu features-submenu',
    href: '#',
    title: '  Post Production ',
    children: [
      {
        href: '#',
        title: 'item 1',
      },
    ],
  },
]

export const Policies = [
  {
    className: 'has-submenu features-submenu',
    href: '/about',
    title: ' About MSG Sphere',
    children: [],
  },
  {
    className: 'has-submenu features-submenu',
    href: '/termsandcondition',
    title: ' Terms of use',
    children: [],
  },
  {
    className: 'has-submenu features-submenu',
    href: '/privacypolicy',
    title: ' Privacy Policy',
    children: [],
  },
]
