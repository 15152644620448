const api = process.env.REACT_APP_PUBLIC_URL + '/api'

export const END_POINTS = {
  LOG_IN: `${api}/login`,
  GET_TOPICS: `${api}/topic`,
  GET_ARTICLE: `${api}/article`,
  LOG_OUT: `${api}/logout`,
  POLICY_UPDATE: `${api}/updatePolicyStatus`,
  POLICY: `${api}/get_latest_policy`,
  UPDATE_POLICY: `${api}/update_user_policy_status`,
  ACCEPTED__POLICE: `${api}/get_user_policy_status`
}
