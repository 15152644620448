import React from 'react'

import { NavItem } from './NavItem'
import { MoreInfo } from './constant'
import LogoutButton from '../../Button/LogoutButton'

const DropDown = (props: DropDown) => {
  return (
    <>
      <ul className='dropdown-menu' onClick={() => props.onClick()}>
        <>
          {MoreInfo.map((item, index) => {
            return <NavItem {...item} key={index} onClick={() => props.onClick()} />
          })}
          <li className='nav-item logout'>
            <LogoutButton />
          </li>
        </>
      </ul>
    </>
  )
}

type DropDown = {
  onClick: CallableFunction
}

export default DropDown
