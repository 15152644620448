import React, { useState } from 'react'
import { NavChild, NavItem } from '../../Desktop/NavItem'

const TabNavItem: React.FC<TabProps> = (type) => {
  const [selected, setSelected] = useState('')

  const onClick = (title: string) => {
    setSelected(title === selected ? '' : title)
  }

  return (
    <ul className='nav flex-column'>
      {type.data.map((item, index) => {
        return (
          <NavItem {...item} key={index} onClick={onClick} isSelected={item.title === selected} />
        )
      })}
    </ul>
  )
}

type TabNavItem = {
  className: string
  href: string
  title: string
  children: NavChild[] | []
}
type TabProps = {
  data: TabNavItem[]
}

export default TabNavItem
