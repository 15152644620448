export const FooterContent = [
  {
    className: 'col-md-2 col-2 info-section',
    title: 'INFORMATION',
    links: [
      {
        href: '',
        title: 'Profile & Progress',
        disabled: true,
      },
      {
        href: 'mailto:creators@sphereentertainmentco.com',
        title: 'Contact',
        disabled: false,
      },
      {
        href: '',
        title: 'FAQs & Help',
        disabled: true,
      },
      {
        href: '/about',
        title: 'About   Sphere',
        disabled: false,
      },
      {
        href: '/termsandcondition',
        title: ' Terms Of Use',
        disabled: false,
      },
      {
        href: '/privacypolicy',
        title: ' Privacy Policy',
        disabled: false,
      },
    ],
  },
  {
    className: 'col-md-0  col-0 social-section',
    title: 'SOCIAL',
    links: [
      {
        href: '#',
        title: 'Vimeo',
        disabled: true,
      },
      {
        href: '#',
        title: 'Instagram',
        disabled: true,
      },
      {
        href: '#',
        title: 'YouTube',
        disabled: true,
      },
      {
        href: '#',
        title: 'LinkedIn',
        disabled: true,
      },
    ],
  },
]
