import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { GetTopics } from '../../api/Topic.services'
import { Footer } from '../../components/Footer/Footer'
import { SideNavbar } from '../../components/SideNavbar/Desktop/SideNavbar'
import { MobileSideNavbar } from '../../components/SideNavbar/Mobile/SideNavbar'
import { useSubcategoryContext } from '../../context/Context'
import { useLoaderContext } from '../../context/LoaderContect'
import { useTopicContext } from '../../context/Topic'
import { makeProtectedRoutes } from '../../hoc/makeProtected'
import './Main.scss'
import { usePolicyService } from '../../hooks/usePolicyService'

const Main: React.FC = () => {
  const { topics, setTopic } = useTopicContext()
  const { setIsLoading } = useLoaderContext()
  const { setsubCategory } = useSubcategoryContext()
  const getData = usePolicyService()
  const naviagte = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    const fetchTopic = async () => {
      setIsLoading(true)
      const result = await GetTopics()
      console.log(result.data, 'gettopics');

      if (result.data) {
        getData(result.data.policyAccepted)
      }
      if (!result.error) {
        setIsLoading(false)
        setTopic(result.data.topics)
      } else {
        setIsLoading(false)
      }
      if (result.error && result?.code === 403) {
        naviagte('/login')
      }
    }
    const userInfo = JSON.parse(String(localStorage.getItem('userInfo')))
    if (!userInfo) {
      naviagte('/login')
      return
    }

    fetchTopic()
  }, [])

  useEffect(() => {
    if (topics) {
      const item = topics.find((item) => item.uid === id)
      setsubCategory(item?.subtopics || [])
    }
  }, [id, topics])


  return (
    <div className='grid-container'>
      <section className='main-section'>
        <SideNavbar />
        <MobileSideNavbar />
        <Outlet />
      </section>
      <Footer />
    </div>
  )
}

export const MainWrapper = makeProtectedRoutes(Main)
