import React from 'react'

export const TabContentWrapper: React.FC<TabWrapper> = ({
  children,
  className,
  target,
  labelledBy,
}) => {
  return (
    <div
      className={`tab-pane fade ${className}`}
      id={target}
      role='tabpanel'
      aria-labelledby={labelledBy}
      tabIndex={0}
    >
      {children}
    </div>
  )
}

type TabWrapper = {
  children: React.ReactElement | string
  className: string
  target: string
  labelledBy: string
}
