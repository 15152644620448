import React, { useMemo } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import './FootageVideo.scss'

function FootageVideo() {
    const [searchParams] = useSearchParams()
    const { id } = useParams()
    const params = useParams()
    const data = JSON.parse(params?.data ? params.data : '')
    const selected = searchParams.get('tag')
    const { state } = useLocation();
    // show breadcrumbs based the params
    const breadcrumbs = useMemo(() => {
        return [{ link: `footage/${id}`, text: 'Footage' }, { link: `footage/${id}`, text: 'Footage Video' }]
    }, [searchParams])
    return (
        <>
            <section className='footage-container'>
                <section className='footage-container'>
                    <section className='hero-subsection'>
                        <Breadcrumb route={breadcrumbs} />
                    </section>
                    <div className='pt-5'>
                        <section className='filter-wrapper'>
                            <h1>
                                {data.title}
                            </h1>
                            <h3 className='pb-4'>
                                {data.description}
                            </h3>
                            <div className='iframe-container'>
                                <iframe
                                    className='responsive-iframe'
                                    src={`${data.link}&badge=0&autopause=0&player_id=0&app_id=58479`}
                                    // width='100%'
                                    // height='500'
                                    allow='autoplay; fullscreen'
                                ></iframe>
                            </div>

                            <ul>

                                <div className='video-card pt-4'>
                                    <div className='video-tags'>
                                        <ul>
                                            {data.tags?.map((item: string, key: number) => (
                                                <span key={key} className='tags'>{item}</span>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </ul>
                            <div className='desc pt-3'>
                                <div>
                                    {/* <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s
                                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                                        passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </div> */}
                                    <button className='p-2 mt-4 button'>Test button </button>
                                </div>
                                {/* <div> t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                    \ The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using &apos;Content here,
                                    evolved over the years, sometimes by accident, sometimes on purpos</div> */}
                            </div>
                        </section>
                    </div>
                </section>

            </section>


        </>
    );
}

export default FootageVideo;