import { makeProtectedRoutes } from '../../hoc/makeProtected'
import Category from '../Category'
import '../Home/Content/content.scss'
import '../Home/Features/features.scss'

function HomePage() {
  // category page is reused and called with the article name
  return (<>
    <Category articleName='landing_page' />
  </>
  )
}

const Home = makeProtectedRoutes(HomePage)
export default Home
